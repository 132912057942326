import * as React from "react";

export class ComponentLoader extends React.Component<ComponentLoader.Props, ComponentLoader.State> {
  public render(): JSX.Element {
    return (
      <div className={`loader ${this.props.fullHeight ? "loader-full-height" : ""}`}>
        <div className="loader-content">
          <div className="loader-spinner" />
          {this.props.label ? (
            <div className="loader-label">
              {this.props.label}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export namespace ComponentLoader {
  export interface Props {
    label?: string;
    fullHeight?: boolean;
  }
  export interface State {}
}
