import { EndpointCheckoutDetail, EndpointCheckoutPay } from "thermomix-checkout-shared";

import { Endpoint } from "thermomix-checkout-client-utility";

export const EndpointCheckout = {
  detail: new Endpoint<EndpointCheckoutDetail.Request, EndpointCheckoutDetail.Response>({
    method: "POST",
    path: () => "/api/checkout/detail",
  }),
  pay: new Endpoint<EndpointCheckoutPay.Request, EndpointCheckoutPay.Response>({
    method: "POST",
    path: () => "/api/checkout/pay",
  }),
};
