import * as React from "react";
import * as ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { RouterWithHistory } from "thermomix-checkout-client-view";

export class Bootstrap extends React.Component<Bootstrap.Props, Bootstrap.State> {
  public static mount = (): void => {
    ReactDom.render(
      <Bootstrap />,
      document.getElementById("root"),
    );
  }

  public render(): JSX.Element {
    return (
      <BrowserRouter>
        <RouterWithHistory />
      </BrowserRouter>
    );
  }
}

export namespace Bootstrap {
  export interface Props {}
  export interface State {}
}
