import { Network } from "thermomix-checkout-shared";

import { Logger } from "thermomix-checkout-client-utility";

export class Endpoint<RequestType extends Network.Response, ResponseType extends Network.Response> {
  public static hostname: string = process.env.endpointApi;

  constructor(
    public options: Endpoint.Options<RequestType, ResponseType>,
  ) {}

  public requestOptionsToPayload = (options: Partial<RequestType & { pathParameters: { [key: string]: string } }>): Endpoint.Payload => {
    const payload: Endpoint.Payload = {
      url: new URL(`https://${this.options.hostname || Endpoint.hostname}${this.options.path(options.pathParameters)}`),
    };

    if (options.query) {
      for (const queryStringParameter of Object.keys(options.query)) {
        if (options.query.hasOwnProperty(queryStringParameter)) {
          payload.url.searchParams.append(queryStringParameter, String(options.query[queryStringParameter]));
        }
      }
    }

    payload.headers = {
      ...this.options.headers,
      ...options.headers,
    };

    if (options.body) {
      payload.body = JSON.stringify(options.body);
    }

    return payload;
  }

  public async request(requestOptions: Partial<RequestType & { pathParameters: { [key: string]: string } }>): Promise<ResponseType["body"]> {
    const optionsPayload = this.requestOptionsToPayload(requestOptions);

    const fetchHref: string = optionsPayload.url.href;

    const fetchOptions: RequestInit = {
      method: this.options.method,
      body: optionsPayload.body,
      headers: {
        "Content-Type": "application/json",
        ...optionsPayload.headers,
      },
    };

    Logger.info(`Endpoint:request [request]`, {
      href: fetchHref,
      options: fetchOptions,
    });

    const response = await fetch(fetchHref, fetchOptions)
      .catch((error) => {
        Logger.error(`Endpoint:request [fetch]`, error);

        return {
          ok: false,
          json: (): Promise<any> => Promise.resolve({
            errors: [{
              message: "An unknown error occured.",
            }],
          }),
        };
      });

    let repsonseJson = await response.json();

    if (response.ok) {
      Logger.info(`Endpoint:request [response]`, {
        response: response,
        json: repsonseJson,
      });
    } else {
      Logger.error(`Endpoint:request [response]`, {
        response: response,
        json: repsonseJson,
      });
    }

    if (this.options.formatResponse) {
      repsonseJson = this.options.formatResponse(repsonseJson);
    }

    return repsonseJson;
  }
}

export namespace Endpoint {
  export interface Options<RequestType extends Partial<Network.Request & { pathParameters: { [key: string]: string } }>, ResponseType extends Network.Response> {
    method: "GET" | "PATCH" | "POST" | "PUT" | "DELETE";
    path: (pathParameters?: Partial<RequestType["pathParameters"]>) => string;
    hostname?: string;
    headers?: {
      [key: string]: string;
    };
    formatResponse?: (
      jsonData: any,
    ) => ResponseType["body"];
  }
  export interface Payload {
    url: URL;
    body?: string;
    headers?: {
      [key: string]: string;
    };
  }
}
