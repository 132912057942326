import { InjectScript } from "thermomix-checkout-client-utility";

export class Eway {
  public static styles: string = [
    `font-family: Helvetica;`,
    `height: 44px;`,
    `border-radius: 4px;`,
    `border: solid 1px transparent;`,
    `box-shadow: none;`
  ].join("")

  public static initializeSecureFields = async (ewayKeyPublic: string, callback: Eway.SecureFields.Callback): Promise<void> => {
    await InjectScript(`https://secure.ewaypayments.com/scripts/eWAY.js`);

    const fields = [{
      publicApiKey: ewayKeyPublic,
      fieldDivId: "eway-secure-field-name",
      fieldType: "name",
      styles: Eway.styles,
    },{
      publicApiKey: ewayKeyPublic,
      fieldDivId: "eway-secure-field-card",
      fieldType: "card",
      styles: Eway.styles,
    },{
      publicApiKey: ewayKeyPublic,
      fieldDivId: "eway-secure-field-expiry",
      fieldType: "expirytext",
      styles: Eway.styles,
    },{
      publicApiKey: ewayKeyPublic,
      fieldDivId: "eway-secure-field-cvn",
      fieldType: "cvn",
      styles: Eway.styles,
    }];

    for (const field of fields) {
      (window as any).eWAY.setupSecureField(field, callback);
    }
  }
}

export namespace Eway {
  export namespace SecureFields {
    export interface Event {
      secureFieldCode: string;
      errors: Array<any>;
      targetField: string;
      fieldValid: boolean;
      valueIsSaved: boolean;
      valueIsValid: boolean;
    }
    export type Callback = (event: Eway.SecureFields.Event) => void;
  }
}

