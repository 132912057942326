import * as React from "react";

import { EndpointCheckoutDetail } from "thermomix-checkout-shared";

import { Logger, Eway, Toolbox } from "thermomix-checkout-client-utility";
import { EndpointCheckout } from "thermomix-checkout-client-endpoint";
import { ComponentLoader, SvgUrl } from "thermomix-checkout-client-view";

export class PageCheckout extends React.Component<PageCheckout.Props, PageCheckout.State> {
  public state: PageCheckout.State = {
    checkoutPayments: {
      paymentDateSecond: Toolbox.dateAddDays(30, new Date()),
      paymentDateThird: Toolbox.dateAddDays(60, new Date()),
    },
    checkoutData: null,
    checkoutDataError: null,
    checkoutDataLoading: true,
    checkoutDetails: {
      shopifyPermanentDomain: "",
      shopifyOrderId: "",
    },
    checkoutForm: {
      useExistingToken: false,
      ewaySecureFieldCode: "",
      ewaySecureFieldValid: {
        name: true,
        card: true,
        expiry: true,
        cvn: true,
      },
      ewaySecureFieldValue: {
        name: false,
        card: false,
        expiry: false,
        cvn: false,
      },
    },
    checkoutFormError: this.checkoutErrorFromUrl(),
    checkoutFormSubmitting: false,
    checkoutFormProcessing: false,
  };

  public checkoutCodeFromUrl (): string {
    const url = new URL(window.location.href);

    return url.searchParams.get("code");
  }

  public checkoutErrorFromUrl (): string {
    const url = new URL(window.location.href);

    return url.searchParams.get("error");
  }

  public async componentDidMount(): Promise<void> {
    await this.fetchCheckoutData();
    await Eway.initializeSecureFields(this.state.checkoutData.ewayKeyPublic, this.handleCallbackEwaySecureFields);
  }

  public fetchCheckoutData = async (): Promise<void> => {
    const checkoutCode = this.checkoutCodeFromUrl();
    const checkoutDetails = JSON.parse(atob(checkoutCode));

    const responseCheckoutDetail = await EndpointCheckout.detail.request({
      body: {
        data: {
          checkoutCode: checkoutCode,
        },
      },
    });

    if (!responseCheckoutDetail.errors) {
      this.setState({
        checkoutData: responseCheckoutDetail.data,
        checkoutDetails: checkoutDetails,
      });
    } else {
      this.setState({
        checkoutDataError: responseCheckoutDetail.errors[0].message,
      });
    }

    this.setState({
      checkoutDataLoading: false,
    });
  }

  public checkoutFormValid = (): boolean => {
    const validExistingToken = this.state.checkoutForm.useExistingToken;
    const validEwaySecureFieldCode = Boolean(this.state.checkoutForm.ewaySecureFieldCode);
    const validEwaySecureFieldValid = Object.keys(this.state.checkoutForm.ewaySecureFieldValid)
      .reduce((checkoutFormValid, ewaySecureFieldKey: keyof PageCheckout.State["checkoutForm"]["ewaySecureFieldValid"]) => {
        return checkoutFormValid ? this.state.checkoutForm.ewaySecureFieldValid[ewaySecureFieldKey] : false;
      }, true);
    const validEwaySecureFieldValue = Object.keys(this.state.checkoutForm.ewaySecureFieldValue)
      .reduce((checkoutFormValid, ewaySecureFieldKey: keyof PageCheckout.State["checkoutForm"]["ewaySecureFieldValue"]) => {
        return checkoutFormValid ? this.state.checkoutForm.ewaySecureFieldValue[ewaySecureFieldKey] : false;
      }, true);

    return validExistingToken || validEwaySecureFieldCode && validEwaySecureFieldValid && validEwaySecureFieldValue;
  }

  public handleCallbackEwaySecureFields = async (event: Eway.SecureFields.Event) => {
    if (!event.valueIsValid || event.errors) {
      this.setState((prevState) => ({
        checkoutForm: {
          ...prevState.checkoutForm,
          ewaySecureFieldValid: {
            ...prevState.checkoutForm.ewaySecureFieldValid,
            [event.targetField]: false,
          },
        },
      }));

      return;
    }

    this.setState((prevState) => ({
      checkoutForm: {
        ...prevState.checkoutForm,
        ewaySecureFieldCode: event.secureFieldCode,
        ewaySecureFieldValid: {
          ...prevState.checkoutForm.ewaySecureFieldValid,
          [event.targetField]: true,
        },
        ewaySecureFieldValue: {
          ...prevState.checkoutForm.ewaySecureFieldValue,
          [event.targetField]: true,
        },
      },
    }));
  }

  public handleSubmitCheckout = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    this.setState({
      checkoutFormSubmitting: true,
    });

    if (!this.state.checkoutForm.useExistingToken && !this.state.checkoutForm.ewaySecureFieldCode) {
      Logger.error("PageCheckout:handleSubmitCheckout", new Error("Missing EWay Secure Field Code."));

      this.setState({
        checkoutFormSubmitting: false,
      });

      return;
    }

    this.setState({
      checkoutFormProcessing: true,
    });

    const responseCheckoutPay = await EndpointCheckout.pay.request({
      body: {
        data: {
          checkoutCode: this.checkoutCodeFromUrl(),
          ewaySecureFieldCode: !this.state.checkoutForm.useExistingToken ? this.state.checkoutForm.ewaySecureFieldCode : undefined,
        },
      },
    });

    if (!responseCheckoutPay.errors) {
      window.location.href = responseCheckoutPay.data.shopifyOrderStatusUrl;
    } else {
      /** NOTE: We need to reload the page due to quirks in the EWay Javascript Library which make it problematic to re-initialize the Secure Field Code */
      // window.location.search = `?code=${this.checkoutShopifyPermanentDomain()}&error=${responseCheckoutPay.errors[0].message}`;
      this.setState({
        checkoutFormSubmitting: false,
        checkoutFormProcessing: false,
        checkoutFormError: responseCheckoutPay.errors[0].message,
      });
    }
  }

  public renderCheckoutForm = (): JSX.Element => {
    if (this.state.checkoutFormError) {
      return (
        <div className="col col-12 card-section">
          <div className="col col-12 card-section-callout card-section-callout-error px2 mb2">
            <div className="card-section-header center bold mt1">{this.state.checkoutFormError}</div>
          </div>
          <a
            className="col col-12 p1 checkout-try-again-button center"
            href={`/checkout?code=${this.checkoutCodeFromUrl()}`}
          >
            Try Again
          </a>
        </div>
      );
    }

    if (this.state.checkoutFormProcessing) {
      return (
        <div>
          <ComponentLoader />
        </div>
      );
    }

    return (
      <form method="POST" onSubmit={this.handleSubmitCheckout}>
        <div id="eway-secure-field-card" className={`eway-field col col-12 mb2 ${this.state.checkoutForm.ewaySecureFieldValid.card ? "" : "eway-field-error"}`}/>
        <div id="eway-secure-field-name" className={`eway-field col col-12 mb2 ${this.state.checkoutForm.ewaySecureFieldValid.name ? "" : "eway-field-error"}`}/>
        <div className="mxn1">
          <div className="col col-6 px1 mb2">
            <div id="eway-secure-field-expiry" className={`eway-field ${this.state.checkoutForm.ewaySecureFieldValid.expiry ? "" : "eway-field-error"}`} />
          </div>
          <div className="col col-6 px1 mb2">
            <div id="eway-secure-field-cvn" className={`eway-field ${this.state.checkoutForm.ewaySecureFieldValid.cvn ? "" : "eway-field-error"}`} />
          </div>
        </div>
        <p>By clicking Confirm I agree to the <a href={`https://${this.state.checkoutDetails.shopifyPermanentDomain}/pages/website-terms-of-use`} target="_blank">Terms and Conditions</a></p>
        <button
          className="col col-12 p1 checkout-submit-button"
          type="submit"
          disabled={this.state.checkoutFormSubmitting || !this.state.checkoutForm.ewaySecureFieldCode}
        >
          Confirm
        </button>
      </form>
    );
  }

  public renderCheckoutData = (): JSX.Element => {
    if (this.state.checkoutDataError) {
      return (
        <div className="clearfix mx-auto card-wrapper mb3">
          <div className="card-section">
            <div className="col col-12 card-section">
              <div className="col col-12 card-section-callout card-section-callout-error px2 mb2">
                <div className="card-section-header center bold mt1">{this.state.checkoutDataError}</div>
              </div>
              <a
                className="col col-12 p1 checkout-try-again-button center"
                href={`/checkout?code=${this.checkoutCodeFromUrl()}`}
              >
                Try Again
              </a>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="clearfix mx-auto card-wrapper mb3">
        <div className="card-section">
          <div className="card-section-header">
            <img src={SvgUrl.icon.bag} className="card-section-header-icon" width="22px" />
            <div className="card-section-header-title">
              Your Bag
            </div>
          </div>
          <div className="col col-12 card-section-break" />
          <div className="col col-12 card-section-content py1">
            {this.state.checkoutData.shopifyLineItems.map((lineItem, lineItemIndex) => (
              <div key={lineItemIndex} className="col col-12 py1">
                <div className="col col-1">{lineItem.quantity}</div>
                <div className="col col-7">{lineItem.name}</div>
                <div className="col col-4 right-align">{Toolbox.amountToMoney(lineItem.originalTotal)}</div>
              </div>
            ))}
            {this.state.checkoutData.shopifyOrderFeeLineItems.map((lineItem, lineItemIndex) => (
              <div key={lineItemIndex} className="col col-12 py1">
                <div className="col col-1">{lineItem.quantity}</div>
                <div className="col col-7">{lineItem.name}</div>
                <div className="col col-4 right-align">{Toolbox.amountToMoney(lineItem.originalTotal)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="col col-12 card-section-break" />
        <div className="card-section">
          <div className="col col-12 card-section-content py1">
            {this.state.checkoutData.shopifyOrder.shippingLine ? (
              <div className="col col-12 py1">
                <div className="col col-8">Shipping</div>
                <div className="col col-4 right-align bold">{Toolbox.amountToMoney(this.state.checkoutData.shopifyOrder.shippingLine.originalPriceSet.shopMoney.amount)}</div>
              </div>
            ) : null}
            <div className="col col-12 py1">
              <div className="col col-8">Total</div>
              <div className="col col-4 right-align bold">
                {Toolbox.amountToMoney(`${((parseFloat(this.state.checkoutData.shopifyOrderAdditionalTotalAmount.shopMoney.amount) * 100) + (parseFloat(this.state.checkoutData.shopifyOrderTotalAmount.shopMoney.amount) * 100)) / 100}`)}
              </div>
            </div>
          </div>
        </div>
        <div className="col col-12 card-section mb2">
          <div className="col col-12 card-section-callout p2">
            <div className="col col-12 mb1">
              <div className="col col-1" style={{ marginTop: "3px" }}><img src={SvgUrl.icon.checkmark.true} width="18px" /></div>
              <div className="col col-8"><span className="bold">Initial Payment</span> {String.fromCharCode(8226)} Today</div>
              <div className="col col-3 right-align bold">{Toolbox.amountToMoney(this.state.checkoutData.shopifyOrderTotalAmount.shopMoney.amount)}</div>
            </div>
            <div className="col col-12 mb1">
              <div className="col col-1" style={{ marginTop: "3px" }}><img src={SvgUrl.icon.checkmark.false} width="18px" /></div>
              <div className="col col-8"><span>2nd Payment</span> {String.fromCharCode(8226)} {Toolbox.dateToWords(this.state.checkoutPayments.paymentDateSecond)}</div>
              <div className="col col-3 right-align bold">{Toolbox.amountToMoney(`${parseFloat(this.state.checkoutData.shopifyOrderAdditionalTotalAmount.shopMoney.amount) / 2}`)}</div>
            </div>
            <div className="col col-12">
              <div className="col col-1" style={{ marginTop: "3px" }}><img src={SvgUrl.icon.checkmark.false} width="18px" /></div>
              <div className="col col-8"><span>3rd Payment</span> {String.fromCharCode(8226)} {Toolbox.dateToWords(this.state.checkoutPayments.paymentDateThird)}</div>
              <div className="col col-3 right-align bold">{Toolbox.amountToMoney(`${parseFloat(this.state.checkoutData.shopifyOrderAdditionalTotalAmount.shopMoney.amount) / 2}`)}</div>
            </div>
          </div>
        </div>
        <div className="card-section">
          <div className="card-section-header">
            <img src={SvgUrl.icon.card} className="card-section-header-icon" width="22px" />
            <div className="card-section-header-title">
              Debit or Credit Card
            </div>
            <div className="col col-right">
              <img src={SvgUrl.logo.visa} className="mr1" width="44px" />
              <img src={SvgUrl.logo.mastercard} width="44px" />
            </div>
          </div>
          <div className="col col-12 card-section-content py1">
            {this.renderCheckoutForm()}
          </div>
        </div>
      </div>
    )
  }

  public render(): JSX.Element {
    if (this.state.checkoutDataLoading) {
      return (
        <div>
          <ComponentLoader fullHeight={true} />
        </div>
      );
    }

    return (
      <div className="col col-12">
        <div className="checkout-header center">
          <img src={SvgUrl.logo.thermomixEz3} width="69px" height="54px" />
        </div>
        {this.renderCheckoutData()}
      </div>
    );
  }
}

export namespace PageCheckout {
  export interface Props {}
  export interface State {
    checkoutPayments: {
      paymentDateSecond: Date;
      paymentDateThird: Date;
    };
    checkoutData: EndpointCheckoutDetail.Response["body"]["data"];
    checkoutDataError: string;
    checkoutDataLoading: boolean;
    checkoutDetails: {
      shopifyPermanentDomain: string;
      shopifyOrderId: string;
    };
    checkoutForm: {
      useExistingToken: boolean;
      ewaySecureFieldCode: string;
      ewaySecureFieldValid: {
        name: boolean;
        card: boolean;
        expiry: boolean;
        cvn: boolean;
      };
      ewaySecureFieldValue: {
        name: boolean;
        card: boolean;
        expiry: boolean;
        cvn: boolean;
      };
    };
    checkoutFormError: string;
    checkoutFormSubmitting: boolean;
    checkoutFormProcessing: boolean;

  }
}
