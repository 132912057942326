export class Toolbox {
  public static amountToMoney = (amount: string): string => {
    return parseFloat(amount).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  public static dateToWords = (date: Date): string => {
    const wordDay = Toolbox.dateToDayWord(date);
    const wordMonth = Toolbox.dateToMonthWord(date);
    const wordYear = date.getFullYear();
    const wordDateOrdinal = Toolbox.dateToDateOrdinal(date);

    return `${wordDay}, ${wordDateOrdinal} ${wordMonth} ${wordYear}`;
  }

  public static dateToDayWord = (date: Date): string => {
    const words = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const wordIndex = date.getDay();

    return words[wordIndex];
  }

  public static dateToMonthWord = (date: Date): string => {
    const words = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const wordIndex = date.getMonth();

    return words[wordIndex];
  }

  public static dateToDateOrdinal = (date: Date): string => {
    const value = date.getDate();

    if (value <= 0) {
      return value.toString();
    }

    const words = ["th", "st", "nd", "rd"];
    const wordIndex = (value > 3 && value < 21) || value % 10 > 3 ? 0 : value % 10;

    return `${value}${words[wordIndex]}`;
  }

  public static dateAddDays = (days: number, date: Date = new Date()): Date => {
    const tempDate = new Date();

    tempDate.setTime(tempDate.getTime() + (days * 24 * 60 * 60 * 1000));

    return tempDate;
  }

  public static safeJsonStringify = (value: any, replacer?: (this: any, key: string, value: any) => any, space?: string | number): string => {
    let jsonString: string;

    try {
      jsonString = JSON.stringify(value, replacer, space);
    } catch(error) {}

    return jsonString;
  }

  public static safeJsonParse = <ParsedType = any>(text: string, reviver?: (this: any, key: string, value: any) => any): ParsedType => {
    let jsonContent: ParsedType;

    try {
      jsonContent = JSON.parse(text, reviver);
    } catch(error) {}

    return jsonContent;
  }
}
