import { Deferred } from "thermomix-checkout-client-utility";

const InjectScriptCache: {
  [key: string]: Deferred<void>;
} = {};

export const InjectScript = (src: string): Promise<void> => {
  if (!InjectScriptCache[src]) {
    InjectScriptCache[src] = new Deferred<void>();

    const script = document.createElement("script");
    script.async = true;
    script.src = src;
    script.addEventListener("load", () => InjectScriptCache[src].resolve());
    script.addEventListener("error", () => InjectScriptCache[src].reject("Error loading script."));
    script.addEventListener("abort", () => InjectScriptCache[src].reject("Script loading aborted."));
    document.head.appendChild(script);
  }

  return InjectScriptCache[src].promise;
};
