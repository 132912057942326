export const SvgUrl = {
  logo: {
    thermomixEz3: require("../../../svg/logo-thermomix-ez3.svg"),
    visa: require("../../../svg/logo-visa.svg"),
    mastercard: require("../../../svg/logo-mastercard.svg"),
  },
  icon: {
    bag: require("../../../svg/icon-bag.svg"),
    card: require("../../../svg/icon-card.svg"),
    checkmark: {
      true: require("../../../svg/icon-checkmark-true.svg"),
      false: require("../../../svg/icon-checkmark-false.svg"),
    }
  },
};
