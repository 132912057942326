import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch, withRouter } from "react-router-dom";

import { PageCheckout } from "thermomix-checkout-client-view";

export class Router extends React.Component<Router.Props, Router.State> {
  public render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/checkout" render={(props) => <PageCheckout {...props} /> }/>
      </Switch>
    );
  }
}

export const RouterWithHistory = withRouter(Router);

export namespace Router {
  export interface Props extends RouteComponentProps {}
  export interface State {}
}
