export class Logger {
  private static enabled = (type: Logger.Type): boolean => {
    const logger = process.env.LOGGER || "";

    if (logger.includes(type) || logger === "*") {
      return true;
    }

    return false;
  }

  private static styleOutput = (label: string, content: Array<any>): Array<any> => {
    return [`%c${label}`, 'font-weight:bold', ...content];
  }

  public static info = (label: string, ...content: Array<any>): void => {
    if (Logger.enabled(Logger.Type.INFO)) {
      console.log(...Logger.styleOutput(label, content)); // tslint:disable-line
    }
  }

  public static error = (label: string, ...content: Array<any>): void => {
    if (Logger.enabled(Logger.Type.ERROR)) {
      console.error(...Logger.styleOutput(label, content)); // tslint:disable-line
    }
  }
}

/* istanbul ignore next */
export namespace Logger {
  export enum Type {
    ERROR = "error",
    INFO = "info",
  }
}

Logger.info("release name", process.env.PACKAGE_RELEASE_NAME);
Logger.info("release hash", process.env.PACKAGE_RELEASE_HASH);
Logger.info("release version", process.env.PACKAGE_RELEASE_VERSION);
